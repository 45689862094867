type getFocusablesProps = {
  /** The container to get focusables from */
  container: HTMLElement
  /** The current focus element */
  currentFocus: HTMLElement
}

export function getFocusables({ container, currentFocus }: getFocusablesProps) {
  // Get focusables in container
  const focusables = container.querySelectorAll('button, [href]') as NodeListOf<
    HTMLAnchorElement | HTMLButtonElement
  >

  // Get current focus index
  let focusIndex = null
  for (let i = 0; i < focusables.length; i++) {
    if (focusables[i] === currentFocus) {
      focusIndex = i
      break
    }
  }

  // Focus data
  return {
    focusables,
    focusIndex,
  }
}
