export const i18n = {
  defaultLocale: 'en',
  locales: ['en', 'da', 'sv'],
  dateLocales: {
    en: 'en-GB',
    da: 'da-DK',
    sv: 'sv-SE',
  },
} as const

export type Locale = (typeof i18n)['locales'][number]
