import { cn } from '@/lib/cn'

type ContainerProps = {
  spacing?: 'sm' | 'base' | 'none' | 'lg'
  maxWidth?: 'base' | 'none'
} & React.HTMLAttributes<HTMLDivElement>

/** Handles spacings and max-widths */
export default function Container({
  children,
  spacing = 'base',
  maxWidth = 'base',
  className,
  ...rest
}: ContainerProps) {
  return (
    <div
      className={cn(
        '_container',
        'w-full',
        {
          'md:p-8': spacing == 'sm',
          'px-4 py-10 md:p-8 xl:p-16 2xl:p-20': spacing == 'base',
          'px-4 md:px-8 xl:px-16 2xl:px-20 py-10 md:py-16 lg:py-20 2xl:py-32': spacing == 'lg',

          'mx-auto': maxWidth !== 'none',
          'max-w-screen-2xl 3xl:max-w-screen-3xl': maxWidth == 'base',
        },
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  )
}
