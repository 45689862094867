import { cn } from '@/lib/cn'

type HeadingProps = {
  size?: keyof typeof textSizes
  tag?: 'p' | 'span' | 'figcaption' | 'li' | 'blockquote' | 'strong' | 'em' | 'u'
} & React.HTMLAttributes<HTMLElement>

export const textSizes = {
  lg: cn('text-base sm:text-lg'),
  md: cn('text-sm sm:text-base'),
  sm: cn('text-xs sm:text-sm'),
}

const baseStyles = cn('_text')

export default function Text({
  children,
  size = 'md',
  tag: Tag = 'p',
  className,
  ...rest
}: HeadingProps) {
  if (!children) return null

  return (
    <Tag className={cn(baseStyles, textSizes[size], className)} {...rest}>
      {children}
    </Tag>
  )
}
