import { cn } from '@/lib/cn'
import _IconWrapper, { type IconBaseProps } from './_IconWrapper'

export default function HoverArrowIcon({ className }: IconBaseProps) {
  const hoverStyles = cn(
    'group-hover:translate-x-0 group-focus-visible:translate-x-0 transition-transform duration-300 ease-in-out',
  )
  return (
    <_IconWrapper className={cn('overflow-hidden', className)}>
      <span className={cn('inline-flex size-full overflow-hidden translate-x-[30%]', hoverStyles)}>
        <span className={cn('inline-flex size-full -translate-x-[55%]', hoverStyles)}>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none'>
            <path d='M16.2 13.7998V10.7998H0V13.7998H16.2Z' fill='currentColor' />
            <path
              d='M21.7078 14.2027L23.8041 12.1064L17.0977 5.3999L15.0013 7.49625L21.7078 14.2027Z'
              fill='currentColor'
            />
            <path
              d='M23.8028 12.1985L21.7065 10.1021L15 16.8086L17.0964 18.905L23.8028 12.1985Z'
              fill='currentColor'
            />
          </svg>
        </span>
      </span>
    </_IconWrapper>
  )
}
