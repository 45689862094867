import Link, { LinkProps } from 'next/link'

/**  Create NextLinkProps by omitting dublicated keys wihtin AnchorHTMLAttributes and LinkProps */
type NextLinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
  LinkProps

export type BaseLinkObject = {
  title: string
  href: string
  target?: string
  prefetch?: boolean
}

export type BaseLinkProps = NextLinkProps

/**
 * Extended `next/link` component.
 * The file exports the type `BaseLinkObject`, which which makes sure that CMS converters are returning valid data.
 */
export default function BaseLink({ href, children, ...rest }: BaseLinkProps) {
  if (!href) return null

  return (
    <Link href={href} {...rest}>
      {children}
    </Link>
  )
}
