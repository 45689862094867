import { cn } from '@/lib/cn'
import { textSizes } from '../Text/Text'

import HoverArrowIcon from '@/components/icons/HoverArrowIcon'
import BaseLink, { BaseLinkProps } from '../BaseLink/BaseLink'

/** HTMLButtonProps represents the props for a plain <button> element. */
type HTMLButtonProps = {
  href?: never
} & React.ButtonHTMLAttributes<HTMLButtonElement>

/** ButtonProps represents the props for the Button component. */
type ButtonProps = (BaseLinkProps | HTMLButtonProps) & {
  iconOnly?: boolean
  noIcon?: boolean
  customIcon?: React.ReactNode
}

/**
 * If `href` is provided, the button will be rendered as a Link.
 * Otherwise, it will be rendered as a button.
 */
export default function Button({
  children,
  className,
  iconOnly = false,
  noIcon = false,
  customIcon,
  href,
  ...rest
}: ButtonProps) {
  if (!children && !href) return null

  const buttonStyle = cn(
    '_button',
    textSizes.md,
    'inline-flex items-center py-2 gap-2 px-6 bg-surface-cta text-text-cta font-bold group',
    { 'rounded-full p-4': iconOnly },
    className,
  )

  function InnerContent() {
    return (
      <>
        <span className={cn({ 'sr-only': iconOnly })}>{children}</span>
        {!noIcon && (
          <>
            {customIcon ? (
              <span className='[&>span]:size-6 size-6'>{customIcon}</span>
            ) : (
              <HoverArrowIcon className='size-6' />
            )}
          </>
        )}
      </>
    )
  }

  if (href) {
    return (
      <BaseLink className={buttonStyle} href={href} {...(rest as Omit<BaseLinkProps, 'href'>)}>
        <InnerContent />
      </BaseLink>
    )
  }

  return (
    <button className={buttonStyle} {...(rest as HTMLButtonProps)}>
      <InnerContent />
    </button>
  )
}
