import { cn } from '@/lib/cn'

export type IconBaseProps = {
  className?: string
}

type IconWrapperProps = IconBaseProps & {
  children: React.ReactNode
}

export default function _IconWrapper({ children, className }: IconWrapperProps) {
  return (
    <span className={cn('_icon-wrapper inline-block pointer-events-none', className)}>
      {children}
    </span>
  )
}
