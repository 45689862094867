'use client'

import BaseLink, { BaseLinkObject } from '@/components/atoms/BaseLink/BaseLink'
import Button from '@/components/atoms/Button/Button'
import Logo from '@/components/atoms/Logo/Logo'
import { i18n, Locale } from '@/i18n'
import { cn } from '@/lib/cn'
import Link from 'next/link'
import BurgerButton from './BurgerButton'
import { useEffect, useId, useState } from 'react'
import Container from '@/components/atoms/Container/Container'
import { getFocusables } from '@/lib/getFocusables'

type NavigationProps = {
  links: BaseLinkObject[]
  registerLink?: BaseLinkObject
  logInLink?: BaseLinkObject
  locale: Locale
  variant: 'static' | 'sticky'
  fixedFadeIn?: boolean
}

export default function Navigation({
  links,
  registerLink,
  logInLink,
  locale,
  variant,
  fixedFadeIn,
}: NavigationProps) {
  const [open, setOpen] = useState(false)
  const [hidden, setHidden] = useState(Boolean(fixedFadeIn && variant === 'sticky'))
  const id = useId().replaceAll(':', '')
  const menuButtonId = `menu-button-${id}`
  const menuPaneId = `menu-pane-${id}`

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      // Handle escape key
      if (event.code == 'Escape') {
        return setOpen(false)
      }

      // Handle tab key (focus trap)
      if (event.code == 'Tab') {
        // Assure target, container and menu button
        const target = (event.target as HTMLElement) || undefined
        const container =
          (document.querySelector(
            `#${menuPaneId}${window.innerWidth >= 768 ? ' > :first-child > :first-child' : ''}`,
          ) as HTMLElement) || undefined
        const menuButton =
          (document.querySelector(`#${menuButtonId}`) as HTMLButtonElement) || undefined
        if (!container || !target || !menuButton) return

        // Get focusable elements and currnet focus index
        const { focusables } = getFocusables({ container, currentFocus: target })
        const lastMenuItem = focusables[focusables.length - 1]
        if (!lastMenuItem) return

        // Trap focus to last menu item when tabbing backwards on #menuButtonId
        if (target?.id == menuButtonId && event.shiftKey) {
          if (lastMenuItem) {
            event.preventDefault()
            return lastMenuItem.focus()
          }
        }

        // Trap focus to menu button when tabbing forwards on last menu item
        if (target === lastMenuItem && !event.shiftKey) {
          if (menuButtonId) {
            event.preventDefault()
            return menuButton.focus()
          }
        }
      }
    }

    if (open) {
      const body = document.body
      body.classList.add('overflow-hidden')
      window.addEventListener('keydown', handleKeyDown)
    } else {
      const body = document.body
      body.classList.remove('overflow-hidden')
      window.removeEventListener('keydown', handleKeyDown)
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [open, menuButtonId, menuPaneId])

  /** Handle fade in after x scroll */
  useEffect(() => {
    if (!fixedFadeIn || variant !== 'sticky') return
    function handleScroll() {
      if (window.scrollY > window.innerHeight / 6) {
        setHidden(false)
      } else {
        setHidden(true)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [fixedFadeIn, variant])

  /** Set open to false on breakpoint where burger menu is not needed */
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1280) {
        setOpen(false)
      }
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <header
        className={cn({
          'sticky w-full top-0 z-[100] py-2 md:py-4 pl-2 md:pl-4 xl:pl-12 2xl:pl-16':
            variant === 'sticky',
          'fixed xl:sticky transition-transform duration-200 ease-in-out [&:has(*:focus-visible)]:translate-y-0':
            variant === 'sticky' && fixedFadeIn,
          '-translate-y-full xl:translate-y-0': hidden,
          'hidden xl:block': variant === 'static',
          'text-surface-solid translate-y-0': open,
        })}
      >
        <nav
          className={cn(
            'items-center p-4 md:pr-8',
            {
              'grid grid-cols-[1fr,auto] xl:gap-8 xl:grid-cols-[auto,1fr,auto] 3xl:grid-cols-[1fr,auto,1fr]':
                variant === 'sticky',
              'flex justify-between md:px-8': variant === 'static',
            },
            // Background
            'backdrop-blur-lg bg-[#fff6]',
            'transition-[background-color,backdrop-filter] duration-300 ease-in-out',
          )}
        >
          <MenuLinks
            links={links}
            variant='desktop'
            className={cn('hidden xl:block 3xl:-order-2', {
              'xl:block': variant === 'static',
            })}
            setOpen={setOpen}
          />
          {variant === 'sticky' && <HomeLink locale={locale} className='xl:-order-1' />}
          <div className='flex gap-6 items-center 3xl:place-self-end h-full'>
            <AuthLinks
              registerLink={registerLink}
              logInLink={logInLink}
              variant='desktop'
              className='hidden md:block'
            />
            {variant === 'sticky' && (
              <BurgerButton
                onClick={() => setOpen(!open)}
                open={open}
                className='xl:hidden'
                id={menuButtonId}
                aria-expanded={open}
                aria-controls={menuPaneId}
              />
            )}
          </div>

          {/* Mobile menu panel */}
          {variant === 'sticky' && (
            <div
              className={cn(
                'absolute -top-2 md:-top-4 right-0 w-screen md:w-1/2 h-dvh -z-10 overflow-hidden',
                {
                  'pointer-events-none': !open,
                },
              )}
            >
              <div
                className={cn(
                  'absolute top-0 left-full size-full bg-surface-solid theme-brand-1',
                  'transition-transform duration-500 ease-in-out',
                  {
                    '-translate-x-full': open,
                  },
                )}
                id={menuPaneId}
                aria-hidden={!open}
                role='menu'
                aria-labelledby={menuButtonId}
              >
                <Container className='!pt-36 h-full flex flex-col justify-between'>
                  <MenuLinks
                    links={links}
                    variant='mobile'
                    className='xl:hidden'
                    tabIndex={!open ? -1 : undefined}
                    open={open}
                    setOpen={setOpen}
                    useMenuItem
                  />
                  <AuthLinks
                    registerLink={registerLink}
                    logInLink={logInLink}
                    variant='mobile'
                    tabIndex={!open ? -1 : undefined}
                    className='md:hidden'
                    useMenuItem
                  />
                </Container>
              </div>
            </div>
          )}
        </nav>
      </header>
    </>
  )
}

type HomeLinkProps = {
  locale: Locale
  className?: string
}

function HomeLink({ locale, className }: HomeLinkProps) {
  return (
    <div className={cn('flex items-center', className)}>
      <Link
        href={`/${locale === i18n.defaultLocale ? '' : locale}`}
        className={'inline-block h-8 3xl:h-11'}
      >
        <span className='sr-only'>Epico</span>
        <Logo
          className='h-full w-auto transition-colors duration-300 ease-in-out md:text-text'
          aria-hidden
        />
      </Link>
    </div>
  )
}

type MenuLinksProps = {
  links: BaseLinkObject[]
  variant: 'mobile' | 'desktop'
  open?: boolean
  tabIndex?: number
  className?: string
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  useMenuItem?: boolean
}

function MenuLinks({
  links,
  variant,
  open,
  tabIndex,
  className,
  setOpen,
  useMenuItem,
}: MenuLinksProps) {
  return (
    <div className={className}>
      <ul
        className={cn('[&:has(a:hover)_a]:opacity-40', {
          // 'grid gap-5': variant === 'mobile',
          'flex gap-6': variant === 'desktop',
        })}
      >
        {links?.map(({ href, title, prefetch, target }, index) => {
          if (!href) return
          return (
            <li
              key={index}
              className={cn({
                'text-center transition-[transform,opacity] duration-500 ease-in-out':
                  variant === 'mobile',
                'translate-x-10 opacity-0': !open && variant === 'mobile',
              })}
              style={{
                transitionDelay: `${(index + 1) * 0.1}s`,
              }}
            >
              <BaseLink
                href={href}
                className={cn('group hover:!opacity-100 transition-opacity duration-200', {
                  'text-2xl font-bold py-3 inline-block': variant === 'mobile',
                  'text-sm font-bold py-1 3xl:py-2 relative': variant === 'desktop',
                })}
                prefetch={prefetch}
                target={target}
                tabIndex={tabIndex}
                onClick={() => {
                  setOpen(false)
                }}
                role={useMenuItem ? 'menuitem' : undefined}
              >
                <span>{title}</span>
                {variant === 'desktop' && (
                  <span
                    className='block absolute theme-brand-2 bottom-0 -left-[0.75rem] w-[calc(100%+1.5rem)] h-[1.5px] bg-surface-solid opacity-0 group-hover:opacity-100 group-hover:duration-200 transition-opacity duration-500 ease-in-out outline-none'
                    aria-hidden
                  />
                )}
              </BaseLink>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

type AuthLinksProps = {
  registerLink?: BaseLinkObject
  logInLink?: BaseLinkObject
  variant: 'mobile' | 'desktop'
  tabIndex?: number
  className?: string
  useMenuItem?: boolean
}

function AuthLinks({
  registerLink,
  logInLink,
  variant,
  tabIndex,
  className,
  useMenuItem,
}: AuthLinksProps) {
  return (
    <div className={className}>
      <div
        className={cn({
          'flex flex-col gap-4 max-w-md w-full mx-auto': variant === 'mobile',
          'flex gap-4 items-center': variant === 'desktop',
        })}
      >
        {registerLink?.href && (
          <BaseLink
            className={cn('transition-colors duration-300 ease-in-out', {
              'text-center font-bold': variant === 'mobile',
              'text-sm font-bold': variant === 'desktop',
            })}
            href={registerLink.href}
            prefetch={registerLink.prefetch}
            target={registerLink.target}
            tabIndex={tabIndex}
            role={useMenuItem ? 'menuitem' : undefined}
          >
            {registerLink.title}
          </BaseLink>
        )}
        {logInLink?.href && (
          <Button
            href={logInLink.href}
            className={cn({ 'flex justify-center items-center': variant === 'mobile' })}
            tabIndex={tabIndex}
            role={useMenuItem ? 'menuitem' : undefined}
            target='_blank'
          >
            {logInLink.title}
          </Button>
        )}
      </div>
    </div>
  )
}
