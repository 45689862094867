import { cn } from '@/lib/cn'

type LogoProps = {
  className?: string
} & React.HTMLAttributes<HTMLSpanElement>

export default function Logo({ className, ...rest }: LogoProps) {
  return (
    <span className={cn('_logo block aspect-[468/158]', className)} {...rest}>
      <svg viewBox='0 0 468 158' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M140.063 116.659V158H111.298V32.8459H140.063V43.2282C146.455 34.356 157.923 30.7694 166.759 30.7694C191.012 30.7694 208.121 48.5137 208.121 79.4719C208.121 109.864 189.884 127.608 165.255 127.608C156.419 127.608 145.327 124.399 140.063 116.659ZM158.675 53.988C147.395 53.988 138.747 62.6714 138.747 79.4719C138.747 95.8949 147.395 104.389 158.675 104.389C170.144 104.389 178.792 94.3847 178.792 79.2831C178.792 64.3704 170.144 53.988 158.675 53.988Z'
          fill='currentColor'
        />
        <path
          d='M223.11 32.8459H251.874V125.343H223.11V32.8459ZM223.11 0H251.874V23.2186H223.11V0Z'
          fill='currentColor'
        />
        <path
          d='M314.987 30.7694C334.54 30.7694 355.408 39.6416 361.048 63.8041L334.164 68.3345C331.72 58.7073 325.139 53.988 316.679 53.988C306.339 53.988 296.186 60.9725 296.186 79.0944C296.186 97.7825 306.527 104.389 316.679 104.389C327.395 104.389 333.412 98.1601 335.292 90.043L362.176 94.5735C355.596 120.057 333.788 127.608 314.987 127.608C283.966 127.608 266.858 107.41 266.858 78.9056C266.858 50.5902 286.41 30.7694 314.987 30.7694Z'
          fill='currentColor'
        />
        <path
          d='M399.002 79.2831C399.002 95.3286 407.086 104.389 418.743 104.389C430.211 104.389 438.671 95.3286 438.671 79.2831C438.671 63.049 430.211 53.988 418.743 53.988C407.274 53.988 399.002 63.049 399.002 79.2831ZM369.673 79.2831C369.673 46.0597 392.61 30.7694 418.743 30.7694C444.687 30.7694 468 46.0597 468 79.2831C468 112.507 444.687 127.608 418.743 127.608C392.61 127.608 369.673 112.507 369.673 79.2831Z'
          fill='currentColor'
        />
        <path
          d='M0 0V125.343H97.1988V98.9152H29.8929V74.1864V47.7587V26.4277H97.1988V0H0Z'
          fill='currentColor'
        />
        <path d='M97.1988 74.1864V47.7587H51.7015V74.1864H97.1988Z' fill='currentColor' />
      </svg>
    </span>
  )
}
