import { cn } from '@/lib/cn'

export type BurgerButtonProps = {
  open?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>

export default function BurgerButton({ open, className, ...rest }: BurgerButtonProps) {
  const strokeStyle = cn('w-4 h-px bg-text absolute transition-all duration-300', {
    'bg-surface-solid': open,
  })

  return (
    <button className={cn('group p-2', className)} {...rest}>
      <span
        className={cn(
          'relative size-4 flex items-center justify-center transition-transform duration-200',
          {
            'rotate-90': open,
          },
        )}
      >
        <span className='sr-only'>{'Toggle menu'}</span>
        <span
          aria-hidden
          className={cn(strokeStyle, '-translate-y-1', { 'rotate-45 translate-y-0': open })}
        />
        <span aria-hidden className={cn(strokeStyle, { 'rotate-45': open })} />
        <span
          aria-hidden
          className={cn(strokeStyle, 'translate-y-1', { '-rotate-45 translate-y-0': open })}
        />
      </span>
    </button>
  )
}
